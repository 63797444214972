<template>
  <div class="exchange-page">
    <y-tabs :label-title="activationList"
      @switch-click="switchAction"
      :index="curIndex">
      <div class="move-page" slot="content" ref="movePage">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="activation-row swiper-slide" v-show="curIndex === 0">
              <template v-if="waitExchange.length">
                <div class="exchange-card"
                  v-for="(item, index) in waitExchange"
                  :key="index">
                  <div class="exchange-card-row">
                    <div class="card-image">
                      <img :src="item.imageUrl" alt="" />
                    </div>
                    <div class="card-content">
                      <p class="name">{{ item.name }}</p>
                      <p class="desc">{{ item.desc }}</p>
                      <p class="time">获得时间：{{ item.time }}</p>
                    </div>
                    <div class="card-btn" @click="exchangeAction(item)">
                      <img
  src="//download-cos.yofish.com/yofish-gongjushiyebu/20230307171258812-exchange-btn.png" alt="" />
                    </div>
                  </div>
                  <div class="tag-lable" v-if="item.tag">{{ item.tag }}</div>
                </div>
              </template>
              <template v-else>
                <div class="image-empty">
                  <img src="
      //download-cos.yofish.com/yofish-gongjushiyebu/20230308091644618-exchange-empty.png" alt="" />
                  <p class="empty-desc">这里什么都没有</p>
                </div>
              </template>
            </div>
            <div class="activation-row swiper-slide" v-show="curIndex === 1">
              <template v-if="alreadyExchange.length">
                <div class="exchange-card"
                  v-for="(item, index) in alreadyExchange"
                  :key="index"
                  @click="jumpAction(item.type)">
                  <div class="exchange-card-row">
                    <div class="card-image">
                      <img :src="item.imageUrl" alt="" />
                    </div>
                    <div class="card-content">
                      <p class="name">{{ item.name }}</p>
                      <p class="desc">{{ item.desc }}</p>
                      <p class="time">兑换时间：{{ item.time }}</p>
                    </div>
                    <div class="card-lable">
                      <img
  src="//download-cos.yofish.com/yofish-gongjushiyebu/20230308150338937-out-lable.png" alt="" />
                    </div>
                  </div>
                  <div class="tag-lable" v-if="item.tag">{{ item.tag }}</div>
                </div>
              </template>
              <template v-else>
                <div class="image-empty">
                  <img src="
      //download-cos.yofish.com/yofish-gongjushiyebu/20230308091644618-exchange-empty.png" alt="" />
                  <p class="empty-desc">这里什么都没有</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </y-tabs>
    <y-loading v-show="isLoading"/>
  </div>
</template>
<script>
import { getMyBlindboxPrizeApi, exchangePrizeBlindboxApi } from '@/api/base1';
import {
  isArray, getNewsYMDHMS,
  debounce, changeParam,
} from '@/utils';
import { getAppStatus } from '@/utils/app';
import { jsJumpCoupon } from '@/utils/bridge';
import yTabs from '@/components/tab';
import yLoading from '@/components/loading';

export default {
  name: 'DrawExchange',
  components: { yTabs, yLoading },
  data() {
    return {
      activationList: [
        { name: '待兑换', value: 0, isActive: true },
        { name: '已兑换', value: 1 },
      ],
      waitExchange: [
      ], // 待兑换
      alreadyExchange: [], // 已兑换
      prizeList: [],
      curIndex: 0,
      left: 0,
      isLoading: false,
      alreadyStyle: {},
      curTab: 0,
    };
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
    },
    activityId() {
      return 9;
    },
  },
  created() {
    this.getMyPrizeList();
  },
  mounted() {
    document.title = '我的奖品';
    // this.swiperAction();
    document.body.style.overflow = 'hidden';
  },
  methods: {
    switchAction(index) {
      this.curIndex = index;
      // this.Swiper.slideTo(index, 500, false);
      this.$refs.movePage.scrollTo(0, 0);
    },
    async getMyPrizeList(prizeType, jumpUrl) {
      let res;
      const { cuserId, activityId } = this;
      this.isLoading = true;
      try {
        res = await getMyBlindboxPrizeApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        // return this.$toast(res?.desc || '网络异常');
      }
      this.isLoading = false;
      if (res?.code === 1) {
        let prizeList = isArray(res?.results) ? res.results : [];
        // eslint-disable-next-line max-len
        prizeList = prizeList.map((item) => ({ ...item, time: getNewsYMDHMS((item.exchangeStatus === 1 ? item.updateTime : item.createTime), false) }));
        this.prizeList = prizeList;
        // eslint-disable-next-line max-len
        this.waitExchange = prizeList.filter((item) => item.exchangeStatus === 0 || item.exchangeStatus === 2);
        this.alreadyExchange = prizeList.filter((item) => item.exchangeStatus === 1);
        if (prizeType === 1) window.location.href = jumpUrl;
      }
    },
    swiperAction() {
      const that = this;
      this.Swiper = new window.Swiper('.swiper-container', {
        loop: false,
        on: {
          slideChangeTransitionStart() {
            let curIndex = this.activeIndex;
            that.curIndex = curIndex;
            that.activationList.forEach((item) => { item.isActive = false; });
            that.activationList[curIndex].isActive = true;
            that.$refs.movePage.scrollTo(0, 0);
          },
          slideChangeTransitionEnd() {},
        },
      });
    },
    updateExchangeList(id) {
      let { prizeList } = this;
      let prizeData = prizeList.find((item) => item.id === id);
      prizeData.exchangeStatus = 1;
    },
    exchangeAction: debounce(async function (response) {
      let res;
      if (this.submiting) return false;
      this.submiting = true;
      let winningPrizeId = response.id;
      this.isLoading = true;
      const { cuserId, activityId } = this;
      let data = { cuserId, winningPrizeId, activityId };
      data = changeParam(data);
      let appInfo;
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        appInfo = window?.ios?.jzAppInfo();
      } else if (/android/i.test(navigator.userAgent)) {
        appInfo = window?.android?.jzAppInfo();
      }
      if (!appInfo) {
        appInfo = {
          name: 'com.jz.youyu',
          channel: '%E5%AE%98%E7%BD%91',
          version: '5.1.0',
          osType: 1,
        };
        appInfo = JSON.stringify(appInfo);
      }
      appInfo = JSON.parse(appInfo);
      const header = {
        name: appInfo.name,
        osType: appInfo.osType,
        channel: appInfo.channel,
        releaseVersion: appInfo.version,
      };
      try {
        res = await exchangePrizeBlindboxApi({}, data, header);
      } catch (e) {
        console.log(e);
        this.submiting = false;
        this.isLoading = false;
        this.$toast({
          content: e?.desc || '兑换失败',
        });
      }
      this.submiting = false;
      this.isLoading = false;
      if (res?.code === 1) {
        this.prizeVisible = false;
        const { type } = response;
        if (type !== 1) {
          // this.updateExchangeList(winningPrizeId);
          this.$toast({
            content: `兑换成功${type === 2 ? ',可前往有鱼钱包-优惠券查看' : ''}`,
            style: this.toastStyle,
          });
          this.getMyPrizeList();
        }
        if (type === 1) {
          let url = decodeURIComponent(res?.results);
          this.getMyPrizeList(type, url);
        }
      }
    }, 600, true),
    jumpAction(type) {
      type === 2 && jsJumpCoupon();
    },
  },
  beforeDestroy() {
    document.body.style.overflow = '';
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/exchange.scss';
</style>
